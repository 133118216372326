export const condition = [
  {
    code: 1,
    message: '(1) 自愿放弃账号内的所有权益及虚拟资产（包括但不限于账号余额、未使用的优惠券等）'
  },
  {
    code: 2,
    message: '(2) 账户无任何纠纷，包括被他人投诉、被国家机关调查，或处于诉讼、仲裁程序中等'
  },
  {
    code: 3,
    message: '(3) 账户内无未完成的订单、服务'
  },
  {
    code: 4,
    message: '(4) 交易成功的订单与您申请注销账号的时间间隔大于或等于15天'
  },
  {
    code: 5,
    message: '(5) 信用额度已结算'
  }
]
